import React, { useEffect } from 'react'
import Title from '../Components/Title'

export const RankHolder = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const RankHolders = [
        { id: 1, batch: "BATCH 2019", holders: [{ name: "PAVITHRA V", branch: "MCA", CGPA: "8.88", rank: "18" }] },
        { id: 2, batch: "BATCH 2018", holders: [{ name: "PRIYA M", branch: "MCA", CGPA: "8.64", rank: "33" }, { name: "JANANI A", branch: "M.E CSE", CGPA: "8.71", rank: "22" }, { name: "JAYABHARATHI J L", branch: "MBA", CGPA: "8.65", rank: "16" }] },
        { id: 3, batch: "BATCH 2017", holders: [{ name: "LAYAVARJITHA S", branch: "MCA", CGPA: "8.68", rank: "35" }, { name: "RAJALAKSHMI R", branch: "MBA", CGPA: "8.39", rank: "39" }] },
        { id: 4, batch: "BATCH 2016", holders: [{ name: "JAYA BHARATHI J L", branch: "B.E. ECE", CGPA: "8.94", rank: "35" }, { name: "INDUMATHY N", branch: "B.Tech IT", CGPA: "8.54", rank: "50" }, { name: "VITHYA P", branch: "MBA", CGPA: "8.24", rank: "50" }, { name: "SHARMILA R", branch: "MCA", CGPA: "8.97", rank: "17" }, { name: "GAYATHRI S", branch: "MCA", CGPA: "8.75", rank: "37" }, { name: "DEEPIKA P", branch: "M.E CSE", CGPA: "8.49", rank: "39" }, { name: "GOWRI PRIYA A", branch: "M.E CSE", CGPA: "8.65", rank: "50" }, { name: "NADHIYADEVI G", branch: "MBA", CGPA: "8.39", rank: "36" }] },
        {
            id: 5, batch: "BATCH 2015", holders: [
                { name: "K.YAMINI", branch: "EEE", CGPA: "8.76", rank: "40" },
                { name: "R.VITHYA", branch: "M.E(AE)", CGPA: "8.62", rank: "29" },
                { name: "G.RAJESHWARI", branch: "M.E(AE)", CGPA: "8.55", rank: "34" },
                { name: "M.RAJALAKSHMI", branch: "M.E(AE)", CGPA: "8.48", rank: "39" },
                { name: "T.NISHANTHI", branch: "M.E(AE)", CGPA: "8.35", rank: "48" },
                { name: "M.MUNEERUNNISA", branch: "M.E(CSE)", CGPA: "8.71", rank: "31" },
                { name: "V.INDHUMATHI", branch: "M.E(CSE)", CGPA: "8.56", rank: "42" },
                { name: "S.USHA RANI", branch: "M.E(CSE)", CGPA: "8.51", rank: "46" },
                { name: "R.VAISHNAVI", branch: "M.E(CSE)", CGPA: "8.49", rank: "47" },
                { name: "S.ANAND", branch: "M.E(MFG.ENG)", CGPA: "8.84", rank: "9" },
                { name: "S.G.KARTHIKEYAN", branch: "M.E(MFG.ENG)", CGPA: "8.75", rank: "13" },
                { name: "D.USHARANI", branch: "MBA", CGPA: "8.45", rank: "10" },
                { name: "K.INDUMATHI", branch: "MCA", CGPA: "8.68", rank: "42" },
                { name: "T.SASIKALA", branch: "MCA", CGPA: "8.62", rank: "48" }
            ]
        },
        {
            id: 6,
            batch: "BATCH 2014",
            holders: [
                { name: "R.GEETHALAKSHMI", branch: "ECE", CGPA: "8.63", rank: "46" },
                { name: "R.PARTHASARATHY", branch: "MECH", CGPA: "8.69", rank: "47" },
                { name: "P.SUGASINI", branch: "M.E(AE)", CGPA: "8.59", rank: "27" }
            ]
        },
        {
            id: 7,
            batch: "BATCH 2013",
            holders: [
                { name: "V.LAKSHMIKANDAN", branch: "MECH", CGPA: "8.87", rank: "24" },
                { name: "S.MOHANRAJ", branch: "MECH", CGPA: "8.77", rank: "32" },
                { name: "S.VIMALKUMAR", branch: "CSE", CGPA: "8.62", rank: "43" },
                { name: "J.MALATHY", branch: "CSE", CGPA: "8.55", rank: "50" },
                { name: "G.HEMAMALINI", branch: "ECE", CGPA: "8.7", rank: "47" },
                { name: "S.MOHANAPRIYA", branch: "MCA", CGPA: "8.53", rank: "38" },
                { name: "K.SARANYA", branch: "MCA", CGPA: "8.47", rank: "44" }
            ]
        },
        {
            id: 8,
            batch: "BATCH 2012",
            holders: [
                { name: "K.GAJAPRIYA", branch: "MCA", CGPA: "8.49", rank: "48" },
                { name: "M.LEENA DOROTHY", branch: "MBA", CGPA: "8.12", rank: "50" }
            ]
        },
        {
            id: 9,
            batch: "BATCH 2011",
            holders: [
                { name: "D.KATHIRAVAN", branch: "MECH", CGPA: "89", rank: "6" },
                { name: "G.MAGESWARI", branch: "CSE", CGPA: "88", rank: "7" }
            ]
        },
        {
            id: 10,
            batch: "BATCH 2010",
            holders: [
                { name: "E.VAISHALI", branch: "IT", CGPA: "89", rank: "8" },
                { name: "B.VIJAYATHARANI", branch: "IT", CGPA: "88", rank: "24" },
                { name: "N.POONKHUZHALI", branch: "MCA", CGPA: "87", rank: "21" },
                { name: "S.SARANYA", branch: "MCA", CGPA: "86", rank: "47" }
            ]
        },
        {
            id: 11,
            batch: "BATCH 2009",
            holders: [
                { name: "T.S.SAJIN", branch: "MECH", CGPA: "89", rank: "9" },
                { name: "S.BALASUBRAMANIYAM", branch: "EEE", CGPA: "86", rank: "46" },
                { name: "R.JOTHI", branch: "IT", CGPA: "90", rank: "2" },
                { name: "S.UMASELVI", branch: "ECE", CGPA: "88", rank: "34" }
            ]
        },
        {
            id: 12,
            batch: "BATCH 2008",
            holders: [
                { name: "S.SANGITHAA", branch: "ECE", CGPA: "88", rank: "34" },
                { name: "M.LINGAMOORTHY", branch: "EEE", CGPA: "88", rank: "9" }
            ]
        },
        {
            id: 13,
            batch: "BATCH 2007",
            holders: [
                { name: "V.ARUN KUMAR", branch: "MECH", CGPA: "87", rank: "40" },
                { name: "I.ANAND DANIEL", branch: "MECH", CGPA: "86", rank: "47" },
                { name: "K.SENTHAMIZHCHELVI", branch: "ECE", CGPA: "88", rank: "30" }
            ]
        },
        {
            id: 14,
            batch: "BATCH 2006",
            holders: [
                { name: "J.BALAJI", branch: "ECE", CGPA: "90", rank: "10" },
                { name: "S.SAVITHA", branch: "ECE", CGPA: "87", rank: "45" },
                { name: "B.THIYAGARAJAN", branch: "EEE", CGPA: "88", rank: "6" },
                { name: "A.ANNAMALAI", branch: "EEE", CGPA: "85", rank: "41" },
                { name: "A.M.JAFFER SIRAJUDDIN", branch: "MECH", CGPA: "87", rank: "20" },
                { name: "DILIP KUMAR RAVI", branch: "MECH", CGPA: "86", rank: "45" }
            ]
        },
        {
            id: 15,
            batch: "BATCH 2005",
            holders: [
                { name: "M.HARISH KUMAR", branch: "MECH", CGPA: "86", rank: "12" }
            ]
        },
        {
            id: 16,
            batch: "BATCH 2004",
            holders: [
                { name: "V.NAMERAJ", branch: "MECH", CGPA: "86", rank: "10" },
                { name: "OLIVIA RAMYA CHITRANJAN", branch: "EEE", CGPA: "89", rank: "11" },
                { name: "C.POONGOTHAI", branch: "EEE", CGPA: "88", rank: "20" }
            ]
        },
        {
            id: 17,
            batch: "BATCH 2003",
            holders: [
                { name: "R.GANESH", branch: "MECH", CGPA: "88", rank: "19" }
            ]
        },
        {
            id: 18,
            batch: "BATCH 2002",
            holders: [
                { name: "R.PRAKASH", branch: "MECH", CGPA: "85", rank: "10" }
            ]
        },
        {
            id: 19,
            batch: "BATCH 2001",
            holders: [
                { name: "K.AMARNATH SINGH", branch: "MECH", CGPA: "83", rank: "7" },
                { name: "T.SENTHIL KUMAR", branch: "MECH", CGPA: "82", rank: "10" }
            ]
        }


    ]

    return (
        <div className={`container-sm`}>
            <Title title="Rank Holders" />
            <p className='mt-3' style={{ fontWeight: 'bold', fontSize: window.innerWidth < 950 ? '14px' : '16px' }}>Our college ranking position among 550 engineering colleges in Tamilnadu state</p>

            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid black' }}>BATCH</th>
                            <th style={{ border: '1px solid black' }}>ACHIEVER’S NAME</th>
                            <th style={{ border: '1px solid black' }}>BRANCH</th>
                            <th style={{ border: '1px solid black' }}>PERCENTAGE/CGPA</th>
                            <th style={{ border: '1px solid black' }}>RANK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {RankHolders.map(batch => (
                            batch.holders.map((holder, index) => (
                                <tr key={`${batch.id}-${index}`}>
                                    {index === 0 && (
                                        <td rowSpan={batch.holders.length} style={{ border: '1px solid black' }}>
                                            {batch.batch}
                                        </td>
                                    )}
                                    <td style={{ border: '1px solid black' }}>{holder.name}</td>
                                    <td style={{ border: '1px solid black' }}>{holder.branch}</td>
                                    <td style={{ border: '1px solid black' }}>{holder.CGPA}</td>
                                    <td style={{ border: '1px solid black' }}>{holder.rank}</td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}
