import React, { useEffect } from 'react'
import Title from '../Components/Title'
import principal from '../Assets/principal.jpg'
const Principal = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className={`container-sm`}>
            <Title title="Principal Message" />

            <div className="row m-0 mt-4">
                <div className="col-lg-4  flex-column col-sm-12 d-flex align-items-start justify-content-center">
                    <img className='rounded-3 shadow' style={{ border: '1px solid black' }} width='100%' height="350px" src={principal} alt="" />
                </div>
                <div className={`col-lg-8  col-sm-12 p-0 ${window.innerWidth < 600 ? 'mt-4' : 'mt-2'}`}>
                    <p style={{ textAlign: 'justify', fontSize: '17px' }}>
                        Today we are in the fast growing developments globally and our responsibility in providing the
                        education blended with latest technologies in the fields of Information Technology, Computer Science,
                        Electronics Engineering,Mecahnical Engineering ,Civil engineering,Electrical engineering and all the vital
                        engineering and science,Management related areas. Every bit of contribution made by the students of
                        this Institution will make it significant and achievable for the growth of global technological
                        advancement. I believe that during the four years engineering course of any other PG Courses, we have
                        to strive hard to meet the challenges ahead of us. Bestwishes
                    </p>
                </div>
            </div>

        </div>
    )
}
export default Principal