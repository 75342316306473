import React, { useEffect } from 'react'
import Title from '../Components/Title'
import chairman from '../Assets/chairman.jpeg'
const Chairman = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div  className={`container-sm`}>
            <Title title="Chairman Message"  />
                <div className="row m-0 mt-4">
                    <div className="col-lg-6  flex-column col-sm-12 d-flex align-items-start justify-content-center">
                        <img className='rounded-3 shadow' style={{border:'3px solid black'}} width='100%' src={chairman} alt="" />
                    <h3 className='text-center pt-2 w-100 '>"KALVI KAVALAR" B.BOSE</h3>
                    </div>
                    <div className={`col-lg-6  col-sm-12 p-0 ${window.innerWidth < 600 ? 'mt-4' : ''}`}>
                        <p style={{ textAlign: 'justify', fontSize: '16px'}}>
                            The long dreamt vision of mine and the quest to provide quality Technical education to the rural masses of the state started to materialize in the early 1990s with the maiden venture of a polytechnic college in the Kanchipuram District.
                        </p>

                        <p style={{ textAlign: 'justify', fontSize: '16px'}}>
                            Following the overwhelming success of this institution,various institutions were established in diverse disciplines across the rural packets of Kanchipuram Districts.
                        </p>

                        <p style={{ textAlign: 'justify', fontSize: '16px'}}>
                            In continuation to this Dr. B. R. Ambedkar Educational Trust was established,in the year 1994, to provide qualitytechnical education for the rural students of Kanchipuram District.
                        </p>

                        <p style={{ textAlign: 'justify', fontSize: '16px'}}>
                            As a First step, PALLAVAN COLLEGE OF ENGINEERING was established in the same year.  Subsequently, PALLAVAN COLLEGE OF ENGINEERING was established in the year 1999 with bachelor Degree courses in Engineering.  The trust with the motto of "EXCELLENCE THROUGH HARD WORK" extends enough support and guidence for the cruising development of the institutionsto attain excellence in all spheres of engineering.
                        </p>


                    </div>
                </div>

                <div className='mt-4'>
                    <p style={{ textAlign: 'justify',  fontSize: '16px' }}>
                        Due to liberalization, privatization and globalization, avenues for the industrial growth has increased many folds leading to an increased technical manpower demand in the new millennium.  Keeping this in mind, we understand the responsibilities and duties that this new millennium demands.It is our prime concern to empower the young students joining in our college to meet the type of responsibilities and duties that this world needs.                        </p>

                    <p style={{ textAlign: 'justify',  fontSize: '16px'    }}>
                        This is achieved appropriately and efficiently by inculcating the overall personality development of the students of the students viz, Communication Skills, presentation skills, ability to work in a team, leadership qualities, strong basic knowledge of the subjects, updating on current trends, identifying and imparting skills required in the market through the most dedicated and experienced members of the faculty.  Besides above, we motivate them to have a deep sense of creativity, innivation to be a successful entrepreneurs and an interest for research and development.  In addition to above, we train our students to be a successful world class engineers with ethical values.                        </p>

                </div>
              
            </div>
    )
}
export default Chairman