import React, { useEffect } from 'react';
import Title from '../Components/Title';
import { FaTools, FaDesktop, FaCogs, FaBuilding, FaLaptop } from 'react-icons/fa';
import { FaMicrochip } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const UG = () => {
  const listedUG = [
    { id: 1, link: '/BE-ME', title: "B.E - Mechanical Engineering", icon: <FaTools /> },
    { id: 2, link: '/BE-EEE', title: "B.E - Electrical & Electronics Engineering", icon: <FaMicrochip /> },
    { id: 3, link: '/BE-CS', title: "B.E - Computer Science Engineering", icon: <FaDesktop /> },
    { id: 4, link: '/BE-ECE', title: "B.E - Electronics & Communication Engineering", icon: <FaCogs /> },
    { id: 5, link: '/BE-CE', title: "B.E - Civil Engineering", icon: <FaBuilding /> },
    { id: 6, link: '/B-Tech', title: "B.Tech -Information Technology", icon: <FaLaptop /> },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-sm">
      <Title title="Under Graduate" />
      <div className="row g-4 mt-5">
        {listedUG.map((list) => (
          <Link to={list.link} key={list.id} className="box mt-5 col-lg-6 col-md-12 mb-4"> 
            <div className="innerBox d-flex flex-column shadow align-items-center text-center rounded-3 p-3 h-100" style={{ border: '1px solid black' }}>
              <h3 className="pt-4">{list.title}</h3>
              <h5 className='p-0 m-0'>4 Years</h5>
              <div className="circle shadow rounded-circle d-flex align-items-center justify-content-center mt-3" style={{ width: '100px', height: '100px', border: '1px solid black' }}>
                <div className='icon' style={{ fontSize: '2rem', color: 'white' }}>{list.icon}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default UG;
