import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Footer from './Components/Footer';
import Basic from './Department/Basic';
import beMe from './Assets/be-me.jpg'
import beEEE from './Assets/BE-EEE.jpg'
import beCS from './Assets/BE-CS.jpg'
import beECE from './Assets/BE-ECE.jpg'
import beCE from './Assets/BE-CE.jpg'
import BTECH from './Assets/BTECH.jpg'
import MCA from './Assets/MCA.jpg'
import meCS from './Assets/ME-CS.jpg'
import MEME from './Assets/ME-ME.jpg'
import MEAE from './Assets/ME-AE.jpg'
import MBA from './Assets/img7.jpg';
import pdf1 from './Assets/SYLLABUS/B.E.Mech.pdf'
import pdf2 from './Assets/SYLLABUS/BE EEE.pdf'
import pdf3 from './Assets/SYLLABUS/B.E.CSE.pdf'
import pdf4 from './Assets/SYLLABUS/B.E.ECE.pdf'
import pdf5 from './Assets/SYLLABUS/BE.Civil.pdf'
import pdf6 from './Assets/SYLLABUS/B.Tech.IT.pdf'
import pdf7 from './Assets/SYLLABUS/MCA.pdf'
import pdf9 from './Assets/SYLLABUS/MEAE.pdf'
import pdf10 from './Assets/SYLLABUS/M.E.CSE.pdf'
import pdf11 from './Assets/SYLLABUS/M.E Mfg.pdf'
import MissionVission from './Pages/MissionVission';
import Chairman from './Pages/Chairman';
import Principal from './Pages/Principal';
import Overview from './Pages/Overview';
import Library from './Pages/Library';
import UG from './Pages/UG';
import PG from './Pages/PG';
import Gallery from './Pages/Gallery';
import Training from './Pages/Training';
import Contact from './Pages/Contact';
import Infrastructure from './Pages/Infrastructure';
import Recruitments from './Pages/Recruitments';
import AICT from './Pages/AICT';
import NSS from './Pages/NSS';
import AntiRaggingCell from './Pages/AntiRaggingCell';
import { Achievements } from './Pages/Achievements';
import { RankHolder } from './Pages/RankHolder';
import Grievance_redressal from './Pages/Grievance_redressal';

function App() {
  const Dept = [
    {id:1, path:"/BE-ME",pdf: pdf1, title:"B.E - Mechanical Engineering" , desc:"Mechanical Engineering department was started in the year of 1997 with an intake of 60 and raised it level to 120 in the year 2012. So far the department has produced 15 university rank holders which include 4th rank. For this act of producing number of university rank holders, Staffordshire University, UK and Education Matter has bestowed the department the best teaching award in engineering. To impart more knowledge to many peoples M.E. Manufacturing Engineering was started in the year 2013 with an intake of 24. <br/> <br/> The scope of the course varies from material research to machinery development, Nano Technology, Smart materials, Aerospace Technology, Missiles technology, CAD/CAM, FMS, CIM, Thermal, Instrumentation, Automation and such other interrelated developments. Mechanical Engineering graduates are sought by employers in almost all sectors of the engineering industries like Automobile, Production, Manufacturing, Transport, Construction, Defence, Electronics and Marine industries.",src:beMe},
    {id:2, path:"/BE-EEE",pdf: pdf2,title:"B.E - Electrical & Electronics Engineering" , desc:"Electrical & Electronics Engineering department was started in the year of 1997 with an intake of 60. The department has produced 8 university rank holders so far. <br/> <br/> The main aim of the course is to upgrade the knowledge's in the Electrical & Electronics Engineering field with the technological advancements in generation, transmission and distribution systems. With the rapid developments in the requirements of renewable energy such as Solar and Wind power generation a huge demand for Electrical Engineers. Electrical & Electronics Engineers are employed in many industries such as Power, Electronics, IT, Design industries and Research & Development.",src:beEEE},
    {id:3,path:"/BE-CS",pdf: pdf3, title:"B.E - Computer Science Engineering" , desc:"The Computer Science & Engineering Department was established in the year 1999 with an intake of 60 and raised to an intake of 120 in 2014. The PG course M.E. Computer Science & Engineering was started in the year 2013 with an intake of 24. <br/> <br/> The objective of the course is to enrich the talents of the students to learn the development of software for various industries. The practical knowledge gained through learning of Operating System, Programming languages, Database Management System, Computer Graphics and Computer Network encompasses students' adaptability to the changing environment, with all-round managerial capabilities and commitment towards the society. <br/> <br/> The opportunities for Computer Engineering graduates are in Software, Hardware, Technical Support, Finance and Communication industries.",src:beCS},
    {id:4,path:"/BE-ECE",pdf: pdf4, title:"B.E - Electronics & Communication Engineering" , desc:"Electronics & Communication Engineering Department was started in the year 2001 with an intake of 60 within a short span of time grown to the level of 120 in the year of 2007. To support high quality education, M.E Applied Electronics was started in the year 2012 with an intake of 24. So far the department has produced 7 university rank holders. The course aims to deepen the knowledge and skills of the students on the basic concepts and theories that will equip them in involving analysis, implementation, operation, production and maintenance of the various applications in the field of Electronics and Communications Engineering. <br/> <br/> Electronics Engineering graduates can avail job opportunities in Telecommunication industries, IT sectors, Mobile Applications and Research & Development.",src:beECE},
    {id:5,path:"/BE-CE", pdf: pdf5,title:"B.E - Civil Engineering" , desc:"Civil Engineering Department was started in the year of 2014 with an intake of 60. The department has state of art lab facilities in Surveys, Strength of Materials, Fluid Mechanics lab and CAD lab with sophisticated modern equipment. <br/> <br/> The scope of the course is to mould the students to possess high qualification skills to become enriched chartered civil engineers. The opportunities are in Government & Private sectors, Construction Industries and Petrochemical Industries.",src:beCE},
    {id:6, path:"/B-Tech",pdf: pdf6,title:"B.Tech -Information Technology" , desc:"The Information Technology Department was established in the year 2001 with an intake of 60 and raised to an intake of 90 in the year 2010. <br/><br/>This department is an engineering division, IT represents a vast category of career options in both software and hardware sectors, that are based on processing, designing, developing, managing or supporting computer based and web based technologies and devices that constitute an IT network. The modern world entirely present with informa- tion technology applications in nearly every aspect of our lives. <br/> <br/> IT graduates can work as programmer, PHP developer, technical consultant, Software developer and graphic de- signer etc. This department has significance that the computer industry has witnessed such phenomenal growth in recent years that IT majors like Infosys & TCS have been the major recruiters across all other branches.",src:BTECH},
    {id:7,path:"/MCA", pdf: pdf7,title:"MCA - Master of Computer Application" , desc:"Department of Master of Computer Application was started in the year 2007 with the intake of 60. From the inception, this department has produced 5 university rank holders. <br/> <br/> This programme is inclined more towards application development and has more emphasis on latest programming language and tools to develop better and faster applications. Students are trained in the fields of System Designing, Application Software Development, Enterprise Resource Planning, Computer Networks, System Administration, Web Designing and Development, Database Administration and Data Mining & Warehousing. <br/> <br/> The opportunities for MCA graduates are Database Management, Technical Support, Finance and Software Industries. Lateral Entry Seats are available for the students those who have completed their under graduates in BCA, B.Sc(IT) & B.Sc (CS).",src:MCA},
    {id:8,path:"/MBA", title:"MBA -  Master of Business Administration" , desc:"Pallavan Engineering College in Kanchipuram offers a distinctive MBA program that blends academic rigor with practical experience. Its commitment to excellence, industry integration, and holistic development prepares students to become competent and ethical business leaders. Whether aspiring to climb the corporate ladder or embark on an entrepreneurial journey, students at PEC are well-equipped to achieve their professional goals. <br/> <br/> The MBA program at PEC offers a well-rounded curriculum designed to meet the demands of today's dynamic business environment. The course includes core subjects such as finance, marketing, human resources, operations, and strategic management, along with electives that allow students to specialize in their areas of interest. ",src:MBA},
    {id:9, path:"/ME-AE",pdf: pdf9,title:"M.E - Applied Electronics" , desc:"The M.E. - Applied Electronics program at Pallavan Engineering College offers a cutting-edge curriculum that covers the latest advancements in electronics, embedded systems, and VLSI design, ensuring students stay at the forefront of technology. <br/>  <br/> The program is led by highly qualified faculty members with extensive academic and industry experience, providing students with deep insights and practical knowledge in applied electronics.   <br/>  <br/> Students have access to modern laboratories equipped with the latest tools and technologies, facilitating hands-on learning and research in various domains of applied electronics. Strong industry partnerships enable regular guest lectures, internships, and collaborative projects, enhancing students' real-world skills and employability.  ",src:MEAE},
    {id:10,path:"/ME-CS",pdf: pdf10, title:"M.E - Computer Science & Engineering" , desc:"The M.E. - Computer Science & Engineering program at Pallavan Engineering College offers an advanced curriculum covering key areas such as artificial intelligence, machine learning, big data analytics, and cybersecurity, ensuring students are well-versed in current and emerging technologies. <br/> <br/> The program boasts a team of experienced professors and industry experts who bring a wealth of knowledge and practical insights, fostering a rich learning environment for students. The college emphasizes research and innovation, encouraging students to undertake significant research projects, participate in national and international conferences, and publish their work in prestigious journals.",src:meCS},
    {id:11,path:"/ME-ME", pdf: pdf11,title:"M.E - Manufacturing Engineering" , desc:"The M.E. - Manufacturing Engineering program at Pallavan Engineering College offers an in-depth curriculum that covers advanced manufacturing processes, automation, CAD/CAM, and sustainable manufacturing practices, preparing students for the complexities of modern manufacturing. <br/> <br/> The program is led by a team of seasoned faculty members who possess extensive academic and industry experience, providing students with both theoretical knowledge and practical expertise. <br/> <br/> Students have access to advanced laboratories equipped with the latest machinery, simulation software, and robotics, facilitating hands-on experience in manufacturing technologies and techniques.",src:MEME},
  ]

  return (
    <div className="App ">
      <Navbar />
      <div style={{ minHeight: '90vh' }}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/vission&mission' element={<MissionVission/>}/>
          <Route path='/chairman' element={<Chairman/>} />
          <Route path='/principal' element={<Principal/>} />
          <Route path='/overview' element={<Overview/>} />
          <Route path='/library' element={<Library/>} />
          <Route path='/undergraduate' element={<UG/>}/>
          <Route path='/postgraduate' element={<PG/>}/>
          <Route path='/gallery' element={<Gallery/>}/>
          <Route path='/training' element={<Training/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/infrastructure' element={<Infrastructure/>}/>
          <Route path='/recruitments' element={<Recruitments/>}/>
          <Route path='/AICTE' element={<AICT/>}/>
          <Route path='/nss' element={<NSS/>}/>
          <Route path='/anti_ragging_cell' element={<AntiRaggingCell/>}/>
          <Route path='/achievements' element={<Achievements/>}/>
          <Route path='/rank-holders' element={<RankHolder/>}/>

          <Route path='/grievance_redressal_cell' element={<Grievance_redressal/>}/>

          {Dept.map(route=>(
          <Route path={route.path} element={<div className={`container-sm`}>
            <Basic title={route.title} img={route?.src} desc={route?.desc} pdf={route?.pdf}/>
            </div>} />

          ))}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
