import React, { useEffect } from 'react'
import Title from '../Components/Title';
import Rec from '../Assets/recruitment.jpg'
import Rec2 from '../Assets/placement.jpg'

const Recruitments = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`container-sm`}>
      <Title title="Recruitments" />

      <div className='row'>
        <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
          <img src={Rec} width='100%' className='rounded-2 ' />
        </div>
        <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
          <div className='mt-2' style={{ textAlign: 'justify' }}>
            <p>
              Pallavan College of Engineering placements saw an overall increase of 3.68% this year (2019-20). The highest CTC offered was 41 LPA. The median package of the batch stood at 4.2 LPA. A total of 100 companies participated in this year placement process and rolled out over 1822 offers. We had companies from various sectors who participated in the placement activities for our students.
            </p>
          </div>
        </div>
      </div>

      <ol className='mt-3'>
              <li>To organise campus interviews for pre-final year students with industries and business houses of repute from all over india</li>
              <li>To prepare students to face campus interviews confidently by arranging training in Aptitute Tests,Group Discussions and Technical and HR interviews through professional trainers.
              </li>
              <li>To facilitate industrial visit and in-plant training ,arranged by the respective departments.
              </li>

              <li>To conduct soft skill training programs to mould the students to qualify themselves for industry needs.
              </li>
              <li>Students are required to come to college in uniforms as specified while campus interview time .
              </li>

            </ol>
<div className='d-flex align-items-center justify-content-center'> 
<img src={Rec2} className="w-50 rounded-3 my-5" />
</div>
    </div>
  )
}

export default Recruitments
