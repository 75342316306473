import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Assets/Logo2.png';
import { GiRotaryPhone } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className="m-0">
      <footer className="text-center text-lg-start">
        <div className="pb-0 bg-dark">
          <section className="mx-3 p-4 text-light  shadow-md">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mt-3">
                <div className={`d-flex mb-2  align-items-center ${window.innerWidth < 950 ? 'justify-content-center' :'justify-content-start'}`}>
                  <img src={Logo} style={{ width: '70px', height: '70px' }} className="rounded-3" alt="Logo" />
                  <h6 style={{ paddingLeft: '8px' }} className="text-uppercase font-weight-bold">
                    PALLAVAN COLLEGE OF ENGINEERING
                  </h6>
                </div>
                <p style={{ textAlign: 'justify', fontSize: '13px' }}>
                  Pallavan College of Engineering is a premier institute established in 1997. The institute is located in Kanchipuram, Tamil Nadu, and it offers Degree courses including PG and UG programs.
                </p>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <div style={{ paddingLeft: '10%' }} className="col-md-4 col-lg-4 col-xl-4 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Useful links
                </h6>
                <p>
                  <Link to='/infrastructure' className="text-light">Infrastructure</Link>
                </p>
                <p>
                  <Link to='/training' className="text-light">Placements</Link>
                </p>
                <p>
                  <Link to='/gallery' className="text-light">Gallery</Link>
                </p>
                <p>
                  <Link to='/contact' className="text-light">Contact Us</Link>
                </p>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                <p><i className="fas fa-home mr-3"></i> Thimmasamudram, Whitegate Kanchipuram-631502, Tamilnadu, India.</p>
                <p><i className="fas fa-envelope mr-3"></i> <a style={{ textDecoration: 'none', color: 'white' }} href='mailto:pce_kanchi@rediffmail.com'> pce_kanchi@rediffmail.com</a> </p>
                <p><GiRotaryPhone size={20} /> <a style={{ textDecoration: 'none', color: 'white' }} href="tel:+044-29592025">  044-29592025 </a> </p>
              </div>
            </div>
          </section>
          <hr className="my-1 d-sm-block d-md-none d-sm-none d-lg-none" />
          <section className="px-3  text-dark bg-light">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                <div className="p-3">
                  © {currentYear} Copyright:&nbsp;
                  <a className="text-dark" href="/">
                    Pallavan College of Engineering
                  </a>
                </div>
              </div>
              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                <a target='_blank' className="btn btn-outline-dark text-dark btn-floating m-1" href='https://www.facebook.com/p/Pallavan-college-of-engineering-100064756326303/' role="button"><i className="fab fa-facebook-f"></i></a>
                <a target='_blank' className="btn btn-outline-dark text-dark btn-floating m-1" role="button" href="https://maps.app.goo.gl/1GCE9TTmCqw3Jmom8"><FaLocationDot className='fab mt-2' size={16} /></a>
              </div>
            </div>
          </section>
        </div>
      </footer>
    </div>
  )
}

export default Footer;
