import React, { useEffect, useState } from 'react'
import PhotoAlbum from 'react-photo-album'
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Lightbox from 'yet-another-react-lightbox';
import img1 from '../Assets/img2.jpg'
import img3 from '../Assets/BE-CE.jpg'
import img4 from '../Assets/BE-CS.jpg'
import img5 from '../Assets/BE-EEE.jpg'
import img6 from '../Assets/be-me.jpg'
import img7 from '../Assets/img1.JPG'
import img8 from '../Assets/img3.JPG'
import img9 from '../Assets/img4.jpg'
import img10 from '../Assets/img5.jpg'
import img11 from '../Assets/img6.jpg'
import img12 from '../Assets/img7.jpg'
import img13 from '../Assets/Gallery/Gallery (1).JPG'
import img14 from '../Assets/Gallery/Gallery (2).JPG'
import img15 from '../Assets/Gallery/Gallery (3).JPG'
import img16 from '../Assets/Gallery/Gallery (4).JPG'
import img17 from '../Assets/G_img1.jpg'
import img18 from '../Assets/G_img2.jpg'
import img19 from '../Assets/G_img3.jpg'
import img20 from '../Assets/G_img4.jpg'
import img21 from '../Assets/Gallery/Gallery (5).jpg'
import img22 from '../Assets/Gallery/Gallery (6).jpg'
import img23 from '../Assets/Gallery/Gallery (7).jpg'
import img24 from '../Assets/Gallery/Gallery (8).jpg'
import img25 from '../Assets/Gallery/Gallery (9).jpg'
import img26 from '../Assets/Gallery/Gallery (10).jpg'
import img27 from '../Assets/Gallery/Gallery (11).jpg'
import img29 from '../Assets/Gallery/Gallery (13).jpg'

import Title from '../Components/Title';

const Gallery = () => {
    const [index, setIndex] = useState(-1);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const photos = [
        { id: 1, src: img1, width: 1080, height: 900 },
        { id: 3, src: img3, width: 1080, height: 900 },
        { id: 4, src: img4, width: 1080, height: 900 },
        { id: 5, src: img5, width: 1080, height: 900 },
        { id: 6, src: img6, width: 1080, height: 900 },
        { id: 7, src: img7, width: 1080, height: 900 },
        { id: 8, src: img8, width: 1080, height: 900 },
        { id: 9, src: img9, width: 1080, height: 900 },
        { id: 10, src: img10, width: 1080, height: 900 },
        { id: 11, src: img11, width: 1080, height: 900 },
        { id: 12, src: img12, width: 1080, height: 900 },
        { id: 13, src: img13, width: 1080, height: 900 },
        { id: 14, src: img14, width: 1080, height: 900 },
        { id: 15, src: img15, width: 1080, height: 900 },
        { id: 16, src: img16, width: 1080, height: 900 },
        { id: 17, src: img17, width: 1080, height: 900 },
        { id: 18, src: img18, width: 1080, height: 900 },
        { id: 19, src: img19, width: 1080, height: 900 },
        { id: 20, src: img20, width: 1080, height: 900 },
        { id: 21, src: img21, width: 1080, height: 900 },
        { id: 22, src: img22, width: 1080, height: 900 },
        { id: 23, src: img23, width: 1080, height: 900 },
        { id: 24, src: img24, width: 1080, height: 900 },
        { id: 25, src: img25, width: 1080, height: 900 },
        { id: 26, src: img26, width: 1080, height: 900 },
        { id: 27, src: img27, width: 1080, height: 900 },
        { id: 29, src: img29, width: 1080, height: 900 },
    ]
    return (
        <div className="container-sm">
            <Title title="Gallery" />
            <div className='mt-4' >
                <div className="lg-react-element mb-5">

                    <PhotoAlbum
                        photos={photos}

                        layout="rows"
                        targetRowHeight={200}
                        onClick={({ index }) => setIndex(index)}
                    />

                    <Lightbox
                        slides={photos}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                        overlayStyles={{
                            background: 'rgba(10, 10, 10, 1.3)',
                        }}
                    />

                </div>
            </div>
        </div>
    )
}

export default Gallery