import React, { useEffect } from 'react'
import Title from '../Components/Title'
import train from '../Assets/training.jpeg'
import train2 from '../Assets/training2.jpg'

const Training = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container-sm">
            <Title title="Training" />
            <div className='mt-2' style={{ textAlign: 'justify' }}>
                <div className='row'>
                    <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
                        <img src={train} width='100%' className='rounded-2 ' />
                    </div>
                    <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
                        <p>
                            Placement training is offered to students at different stages of their academics. Industrial training requirements are identified, training programs are designed and facilitated to students effectively. The training modules are regularly upgraded based on the current industry expectations and interactions with the recruiters.
                        </p>
                        <p>Offering information ,advice,guidance and support for job-seeking students is considered a primary responsibilityof the placement cell.
                        </p>
                     
                    </div>

                </div>
                <p className='mt-3'>The placement cell of Pallavan is acting as a crown of all departments which conducts various training programs and workshops for students to make them employable. Our Students got campus placements in companies like infosys, CTS, Sutherland, HCL, CSS corp, YAMAHA, Axis Bank, etc. Though campus placements and placements assistance.

</p>
                <h4 className='mt-5'>The Placement And Training Department Has Three Major Divisions Based Upon Its Activities</h4>
                <h6 className='mt-2'>INDUSTRY-INSTITUTE INTERACTION CELL:</h6>
                <p>The main objective of the industry-institute interaction cell is to maintain continuous interaction with industry by exchanging the managerial and technical information.  This helps the institute to understand the changing profile of industry so that suitable training can be provided to the students according to the requirements of managerial and technical jobs.  The cell provides for training programmes, workshops, indstrial visits, placement assistance, etc. to the students</p>

                <h6 className='mt-2'>BUREAU OF HIGHER STUDIES:</h6>
                <p>The placement provides guidence for the students to do their higher studies in abroad through conducting courses for TOFEL,GRE,GATE and providing necessary guidance and assistance to the students those who are interested to do higher studies in abroad.</p>
                <h6 className='mt-2'>PLACEMENT CELL:</h6>
                <p>The placement cell has got various objectives such as:</p>

                <div className='row' style={{textAlign:'justify'}}>
                    <div className='col-lg-7 col-md-12 col-sm-12 col-12  d-flex align-items-start flex-column  d-none d-lg-inline-block'>
                        <ol>
                            <li>To organise campus interviews for pre-final and final year students with industries and business houses of repute from all over India.</li>
                            <li>To prepare students to face campus interviews confidently by arranging training in Aptitute Tests, Group Discussions and Technical and HR interviews through professional trainers like TIME INSTITUTE Chennai.
                            </li>
                            <li>To facilitate industrial visit and in-plant training ,arranged by the respective departments.
                            </li>
                            <li>To conduct soft skill training programs to mould the students to qualify themselves for industry needs.
                            </li>
                        </ol>                    </div>
                    <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
                        <img alt="train2" className="rounded-3 mb-2" style={{ objectFit: 'cover' }} src={train2} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px' />
                    </div>
                    <div className='col-lg-7 col-md-12 col-sm-12 col-12  d-flex align-items-start flex-column d-md-block d-sm-block d-lg-none'>
                        <ol >
                            <li>To organise campus interviews for pre-final and final year students with industries and business houses of repute from all over India.</li>
                            <li>To prepare students to face campus interviews confidently by arranging training in Aptitute Tests, Group Discussions and Technical and HR interviews through professional trainers like TIME INSTITUTE Chennai.
                            </li>
                            <li>To facilitate industrial visit and in-plant training ,arranged by the respective departments.
                            </li>
                            <li>To conduct soft skill training programs to mould the students to qualify themselves for industry needs.
                            </li>
                        </ol>                    </div>
                </div>

            </div>
        </div>
    )
}

export default Training
