import React, { useEffect } from 'react'
import Title from '../Components/Title';
import pdf1 from '../Assets/AICT/2024 -2025.pdf'
import pdf2 from '../Assets/AICT/2023-2024.PDF'
import pdf3 from '../Assets/AICT/21-22.PDF'
import pdf4 from '../Assets/AICT/2020-21.pdf'
import PDFIMG from '../Assets/AICT/pdf.png'
const AICT = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const pdfView = [
        { id: 1, pdf: pdf1, title: "2024 - 2025" },
        { id: 2, pdf: pdf2, title: "2023 - 2024" },
        { id: 3, pdf: pdf3, title: "2021 - 2022" },
        { id: 4, pdf: pdf4, title: "2020 - 2021" }
    ]

    return (
        <div className={`container-sm`}>
            <Title title="AICTE Approval" />

            <div className='row gap-3 mt-5 mx-0 d-flex align-items-center justify-content-center'>
                {pdfView.map(list => (
                    <div key={list.id} className='col col-lg-5  border rounded-3 p-3'>
                        <div className='d-flex align-items-center justify-content-center'>            <img src={PDFIMG} width='120px' alt='pdf' />
                        </div>
                        <h5 className='text-center'>EOA Report {list.title}</h5>
                        <div className='d-flex justify-content-between'>
                            <button className='btn btn-primary'>
                                <a className='text-white' href={list.pdf} target="_blank" rel="noreferrer">
                                    view PDF
                                </a>
                            </button>
                            <button className='btn btn-danger'>
                                <a className='text-white' href={list.pdf} download={list.pdf}>
                                    Download PDF
                                </a>
                            </button>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AICT