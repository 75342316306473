import React, { useEffect } from 'react'
import Title from '../Components/Title';

const AntiRaggingCell = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const Anti = [
        {id:1, name: "Dr. k. Sivakumar", Designation:"Principal", email:"pce_kanchi@rediffmail.com"},
        {id:2, name: "M.Girirajan", Designation:"AP/MECH", email:"girirajan.m@gmail.com"},
        {id:3, name: "S.Mangayarkarasi", Designation:"AP/MBA", email:"shivas.tkms@gmail.com"},
        {id:4, name: "K.Ilayakumar", Designation:"AP/MATHS", email:"elaya_123@yahoo.co.in"},
        {id:5, name: "G.Subathra", Designation:"AP/ECE", email:"Subathra.g@gmail.com"},
      ]

  return (
    <div className={`container-sm`}>
    <Title title="Anti Ragging Cell" />

    <div className='table-responsive mt-4'> 
    <table className='table '>
                    <thead className='table-primary'>
                        <tr>
                            <th style={{ border: '1px solid black' }}>Name of the Committee Member</th>
                            <th style={{ border: '1px solid black' }}>Designation</th>
                            <th style={{ border: '1px solid black' }}>E-mail address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Anti.map((list,index) => (
                            <tr key={list.id}>
                                <td style={{ border: '1px solid black' }}>{list.name}</td>
                                <td style={{ border: '1px solid black' }}>{list.Designation}</td>
                                <td style={{ border: '1px solid black' }}><a href={`mailto:${list.email}`}> {list.email} </a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
    </div>
</div>  )
}

export default AntiRaggingCell