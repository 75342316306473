import React, { useState, useEffect } from 'react';
import Logo from '../Assets/Logo2.png';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { GiRotaryPhone } from "react-icons/gi";

const Navbar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [ListItems, setListItems] = useState([
    { title: "Home", link: '/' },
    { title: "About Us",  drop: "AboutUs", isDropdownOpen: false, dropLists: [ { dropTitle: "Overview", dropLink: '/overview' },{ dropTitle: "Vission and Mission", dropLink: '/vission&mission' }, { dropTitle: "Chairman Message", dropLink: '/chairman' }, { dropTitle: "Principal Message", dropLink: '/principal' }] },
    { title: "Acadamics", drop: "Acadamics", isDropdownOpen: false, dropLists: [{ dropTitle: "Library", dropLink: '/library' }, { dropTitle: "Infrastructure", dropLink: '/infrastructure' },  { dropTitle: "AICTE", dropLink: '/AICTE' }, { dropTitle: "Achievments", dropLink: '/achievements' }, , { dropTitle: "Rank Holders", dropLink: '/rank-holders' }] },
    { title: "Department", drop: "Department", isDropdownOpen: false, dropLists: [{ dropTitle: "Under Graduate", dropLink: '/undergraduate' },{ dropTitle: "Post Graduate", dropLink: '/postgraduate' }] },
    { title: "Placement", drop: "Placement", isDropdownOpen: false, dropLists: [{ dropTitle: "Training", dropLink: '/training' }, { dropTitle: "Recruitments", dropLink: '/recruitments' }] },
    { title: "Co-curricular Activities", drop: "Co-curricularActivities", isDropdownOpen: false, dropLists: [{ dropTitle: "National Service Scheme", dropLink: '/nss' }, { dropTitle: "Anti Ragging Cell", dropLink: '/anti_ragging_cell' },  { dropTitle: "Grievance Redressal Cell", dropLink: '/grievance_redressal_cell ' }] },
    { title: "Gallery", link: '/gallery' },
    { title: "Contact", link: '/contact' },
  ]);

  const toggleDropdown = (index) => {
    const updatedListItems = [...ListItems];
    updatedListItems[index].isDropdownOpen = !updatedListItems[index].isDropdownOpen;
    setListItems(updatedListItems);
  };

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const [openDropdown, setOpenDropdown] = useState('')

  const handleCick = (item) => {
    if (item === openDropdown) {
      setOpenDropdown('')
    }
    else
      setOpenDropdown(item)
  }

  return (
    <>
      <div style={{backgroundColor:'#028391'}} className={`offcanvas shadow-lg offcanvas-end${isOffcanvasOpen ? ' show' : ''}`} tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <h3 id="offcanvasRightLabel " className='text-light'>Menu</h3>
          <button type="button" className="btn-close text-light btn-light text-reset border" onClick={toggleOffcanvas} aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav">
            {ListItems.map((list, index) => (
              <li className="nav-item" key={index}>
                {list.drop ?
                  <>
                    <button onClick={() => handleCick(list.drop)} className='w-100 bg-transparent d-flex align-items-center justify-content-between text-light px-0 py-2 my-2  py-0 ' style={{ outline: '0px', border: '0px', textAlign: 'left', fontWeight: '500' }} data-bs-toggle="collapse" data-bs-target={`#${list.drop}`} aria-expanded="false" aria-controls={list.drop}>
                      <span>  {list.title} </span>
                      <span>{openDropdown === list.drop ? <FaMinus /> : <FaPlus />}</span>
                    </button>
                    <div class="collapse" id={list.drop}>
                      <div class="card card-body bg-transparent">
                        {list.dropLists.map((li, ind) => (
                          <Link key={ind} className="nav-link my-2 bg-transparent text-light" style={{ fontWeight: '500' }} to={li.dropLink} onClick={toggleOffcanvas}><FaLongArrowAltRight /> &nbsp;{li.dropTitle}</Link>
                        ))}
                      </div>
                    </div>
                  </>
                  :
                  <Link className="nav-link my-2 text-light  bg-transparent " style={{ fontWeight: '500' }} to={list.link} onClick={toggleOffcanvas}>
                    {list.title}</Link>
                }
              </li>
            ))}
          </ul>
        </div>
      </div>

        <div className={`d-flex px-3 py-1 align-items-start justify-content-between w-100 px-2 ${windowWidth < 700 ? 'flex-column' : ''}`}>
       
          <div className={`w-100 d-flex align-items-start justify-content-between`}>
            <div>   <a className='' style={{ color: 'black', textDecoration: 'none', fontSize: windowWidth < 600 ? '10px' : '16px',padding: windowWidth < 600 ? '0' : '' }} href='tel:+044-29592025'>
              <GiRotaryPhone  color="black" size={windowWidth > 600 ? 20: 17} />&nbsp; 044-29592025</a>
             <br/>
              <a className='' style={{ color: 'black', textDecoration: 'none', fontSize: windowWidth < 600 ? '10px' : '16px' }} href='mailto:pce_kanchi@rediffmail.com'>
                <IoMdMail color="black" size={windowWidth > 600 ? 20: 17}/>&nbsp;pce_kanchi@rediffmail.com
              </a></div>

              <div> 
            <h2 className={`text-primary text-bold textStroke`} style={{ fontSize: windowWidth < 600 ? '10px' : '25px', fontWeight:'700'}}>COUNSELLING CODE : 1209</h2>
           </div>
          </div>
        </div>

        <nav className={`navbar navbar-expand-md  p-2 d-flex flex-column sticky-top`} style={{backgroundColor:'#028391'}} >
        <div className="container-fluid  px-1 sticky-top " >
          
            <div className='d-flex align-items-center  w-100  justify-content-center'>
            <Link className="navbar-brand m-0" to="/">
             <img src={Logo} style={{ width: windowWidth < 1300 ? '70px' : '120px', height: windowWidth < 1300 ? '70px' : '120px' }} className="rounded-3" alt="Logo" />
             </Link> 
              <div className='d-flex align-items-start  w-100  justify-content-center flex-column px-2'>
                <h2 className='m-0 mb-1 text-light' style={{color:'#01204E',textTransform:'uppercase', fontSize: windowWidth < 1000 ? '15px' : '27px', fontWeight:'700' }}>Pallavan College of Engineering</h2>
                
               <div className='d-flex align-items-center justify-content-between w-100'> 
                 <p className='text-light m-0' style={{ fontSize: windowWidth < 600 ? '10px' : '14px' }}>
              Approved by AICTE, <br />Affiliated to Anna University,
              <br />
               ISO 9001:2008 certified Institution
            </p> 
            <button style={{border:'1px solid white'}} className="btn btn-light  navbar-toggler d-md-block d-sm-block d-lg-block d-xxl-none" type="button" onClick={toggleOffcanvas} aria-label="Toggle navigation">
            <span className="navbar-toggler-icon text-light"></span>
          </button>
            </div>
              </div>
            </div>

          <div className={`d-none d-md-none d-sm-none d-lg-none d-xxl-inline-block collapse`} id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto d-flex align-items-center">
              {ListItems.map((list, index) => (
                <li className={`nav-item ${list.drop ? "dropdown" : ''}`} key={index} onMouseEnter={() => toggleDropdown(index)} onMouseLeave={() => toggleDropdown(index)}>
                  {list.drop ? (
                    <>
                      <a style={{ fontWeight: '500', textDecoration: 'none' }} className="mx-3 text-light dropdown-toggle" href="#" id={list.drop} role="button" aria-expanded="false">
                        {list.title}
                      </a>
                      <ul className={`border-light bg-light dropdown-menu${list.isDropdownOpen ? ' show' : ''}`} aria-labelledby={list.drop}>
                        {list.dropLists.map((item, i) => (
                          <li key={i} className='border'>
                            <Link className="dropdown-item dropsLinks "  to={item.dropLink}>{item.dropTitle}</Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <Link style={{ fontWeight: '500', color: 'white' }} className="nav-link mx-2" to={list.link}>{list.title}</Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
