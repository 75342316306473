import React, { useEffect } from 'react';
import Title from '../Components/Title';
import { FaTools, FaDesktop } from 'react-icons/fa';
import { FaMicrochip } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { FaComputer } from "react-icons/fa6";

const PG = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const listedUG = [
    { id: 1, link: '/MCA', title: "MCA - Master of Computer Application", icon: <FaComputer /> },
    { id: 2, link: '/MBA', title: "MBA -  Master of Business Administration", icon: <FaUser /> },
    { id: 3, link: '/ME-AE', title: "M.E - Applied Electronics", icon: <FaMicrochip /> },
    { id: 4, link: '/ME-CS', title: "M.E - Computer Science & Engineering", icon: <FaDesktop /> },
    { id: 5, link: '/ME-ME', title: "M.E - Manufacturing Engineering", icon: <FaTools /> },
  ];

  return (
    <div className="container-sm">
      <Title title="Post Graduate" />
      <div className="row g-4 mt-5">
        {listedUG.map((list) => (
          <Link to={list.link} key={list.id} className="box mt-5 col-lg-6 col-md-12 mb-4"> 
            <div className="innerBox shadow d-flex flex-column align-items-center text-center rounded-3 p-3 h-100" style={{ border: '1px solid black' }}>
              <h3 className="pt-4">{list.title}</h3>
              <h5 className='p-0 m-0'>2 Years</h5>
              <div className="circle shadow rounded-circle d-flex align-items-center justify-content-center mt-3" style={{ width: '100px', height: '100px', border: '1px solid black' }}>
                <div className='icon' style={{ fontSize: '2rem', color: 'white' }}>{list.icon}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default PG;
