import React, { useEffect } from 'react'
import Img from '../Assets/img2.jpg';
import Title from '../Components/Title';
import pdf1 from '../Assets/Overview/2(F) AND 12(B).pdf'


const Overview = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pdfView = [
    { id: 1, pdf: pdf1, title: "2(F) AND 12(B)" },
  ]
  return (
    <div className={`container-sm`}>
       <Title title="Overview"  />
       <div className={`row mb-3 mx-0 `}>
        <div className={`col-lg-4 col-md-12 col-sm-12 col-12 ${window.innerWidth < 980 ? 'p-0' : " "}`} >
        <img src={Img} alt="image1"  className='rounded-3 w-100' style={{border:'3px solid black'}}/>
       <h6 className='text-center'>Pallavan College of Engineering</h6>
        </div>
        <div className={`col-lg-8 col-md-12 col-sm-12 col-12 ${window.innerWidth < 980 ? 'p-0' : " "}`}  style={{textAlign:'justify'}}>
        <p>Pallavan College of Engineering (PCE) approved by All India Council for Technical Education, New Delhi, affiliated to Anna University Chennai and recognized by Government of Tamilnadu was founded in 1997 as a self financing Institution. The college was promoted by Pallavan Education trust. A trust with 29 years of Excellence of Education in Academic. The main objective of the trust is to provide education to rural area students.</p>
       
       <p>Pallavan College of Engineering is located on National Highway-4 connecting Chennai and Bangalore. It is 5 km from the temple city Kanchipuram and 70 km from state capital Chennai. The college is well connected by road and rail to reach different parts of the country.</p>
        </div>

       </div>
     

       <div style={{textAlign:'justify'}}>
       
       <h4>OUR QUALITY POLICY</h4>
       <p> The Quality Policy of Pallavan College of Engineering is to provide high quality technical education to students of Graduate programs in the field of Engineering and Post Graduate students of Management and computer science in the courses, approved by AICTE, through systematic and professional approach for educational and intellectual improvement through effective implementation of quality management system.</p>
      
       <p>	We are committed to ensure continual improvement and continuing review of quality management system, involving all motivated staff and students through compliance of all statutory / regulatory requirements.</p>
      
      <h4 className='mt-4'>PLACEMENT AND TRAINING CENTRE</h4>
      <p>The ultimate aim of Placement and Training centre is to mould students to survey in the competitive world. The activities of Placement Centre enrich the knowledge and guide the students on the road to success. The key activities of the centre as follows,</p>
       
       <ul style={{lineHeight:'25px'}}>
        <li>Promote career counseling through guidance lecture by senior corporate executives.</li>
        <li>Train students by expert training resources.</li>
        <li>Arrange in -plant industrial training to students.</li>
       </ul>
<p> The partial list of companies  which recruited our students are , TCS, Vuram Technology, Infosys, CTS, Alice Blue India Ltd, Genie Consultant, HCL Infosystem, Sutherland Global Services, 3Edge Solutions, CSS Corp,  SPI Global services. </p>
       </div>

       <div className='row gap-3 mt-5 mx-0 my-5 d-flex align-items-center justify-content-center'>
                {pdfView.map(list => (
                    <div key={list.id} className='col col-lg-5  border rounded-3 p-3'>
                    
                        <h5 className='text-center'>EOA Report {list.title}</h5>
                        <div className='d-flex justify-content-between'>
                            <button className='btn btn-primary'>
                                <a className='text-white' href={list.pdf} target="_blank" rel="noreferrer">
                                    view PDF
                                </a>
                            </button>
                            <button className='btn btn-danger'>
                                <a className='text-white' href={list.pdf} download={list.pdf}>
                                    Download PDF
                                </a>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
    </div>
  )
}

export default Overview
