
import React, { useEffect } from 'react'
import Title from '../Components/Title'
import lib1 from '../Assets/img5.jpg'
import lib2 from '../Assets/img6.jpg'


const Library = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`container-sm`}>
      <Title title="Library" />

      <div className='row mt-2'>
        <div className='col-lg-6 col-md-12 col-sm-12 mb-3'>
          <img className='rounded-3 shadow mb-3' style={{ border: '3px solid black' }} width='100%' src={lib1} alt="" />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <p style={{ textAlign: 'justify' }}>Library is the hub for information services in the institute and serves as a major learning and resource centre. It is a creative and innovative partner in supporting the teaching, learning, scholarship and research activities of the institute. With the fast growing collection, both in digital and print forms using the state-of-the-art facilities, the Central library is contributing at an higher extent to provide a world class academic institute. It is located in a separate building comprising two storey, ground and first floor. The location of the central library overlooks beautiful landscape of lush green plants and variety of flowers. The pleasant ambience and the well-furnished well-lighted location create an atmosphere of serenity and thereby inspire the learning and research. It has a spacious area of 13950 Sq. mt / 1,50,156 sq. ft that exudes peaceful learning environment.</p>

        </div>
      </div>

      <div className='row mt-2'>
      <div className='col-lg-6 col-md-12 col-sm-12 mb-3  d-md-block d-sm-block d-lg-none '>
          <img className='rounded-3 shadow mb-3' style={{ border: '3px solid black' }} width='100%' src={lib2} alt="" />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <p style={{ textAlign: 'justify' }}> The collection in the library encompasses 302131 books of 49435 titles varying in subjects like Engineering, Management, Architecture & Design, Law, Pharmacy, Nursing, Dental, Basic Science & Humanities and also includes reference books, 3179 CDs/DVDs and subscribed to 124 print journals and 100 magazines in print and e-resources. Major e-resources such as IEEE Journals, Science Direct, IEEE Proceedings, ASME, ASCE, ACM, Springer, Proquest Academic Complete e-Book collection and Web of Science are accessible to the user community. Central Library is providing 24x7 services through Knimbus mobile app. It facilitates users to get secure remote access of e-Resources with federated search.
          </p>
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12 mb-3  d-none d-lg-inline-block'>
          <img className='rounded-3 shadow mb-3' style={{ border: '3px solid black' }} width='100%' src={lib2} alt="" />
        </div>
      </div>

      <div>

      </div>
    </div>
  )
}

export default Library
