import React, { useEffect } from 'react'
import Title from '../Components/Title'
import { GiRotaryPhone } from "react-icons/gi";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-sm">
      <Title title="Contact Us" />

      <div className='row g-4 mt-5' style={{ minHeight: "100px" }}>
        <div className='col rounded-2'>
        <div className="innerBox shadow d-flex flex-column align-items-center text-center rounded-3 p-3 h-100" style={{ border: '1px solid black' }}>
          <p className='pt-4'> Thimmasamudram, Whitegate Kanchipuram-631502, Tamilnadu, India.</p>
          <div className="circleSmall shadow rounded-circle d-flex align-items-center justify-content-center mt-2" style={{ width: '60px', height: '60px', border: '1px solid black' }}>
            <div className='icon' style={{ fontSize: '1rem', color: 'white' }}><i className="fas fa-home"></i> </div>
          </div>
          </div>
        </div>

        <div className='col rounded-2'>
        <div className="innerBox shadow d-flex flex-column align-items-center text-center rounded-3 p-3 h-100" style={{ border: '1px solid black' }}>
          <p className='pt-4'><a style={{ textDecoration: 'none', color: 'black' }} href='mailto:pce_kanchi@rediffmail.com'> pce_kanchi@rediffmail.com</a></p>
          <div className="circleSmall shadow rounded-circle d-flex align-items-center justify-content-center mt-2" style={{ width: '60px', height: '60px', border: '1px solid black' }}>
            <div className='icon' style={{ fontSize: '1rem', color: 'white' }}><i className="fas fa-envelope"></i> </div>
          </div>
          </div>
        </div>

        <div className='col rounded-2'>
        <div className="innerBox shadow d-flex flex-column align-items-center text-center rounded-3 p-3 h-100" style={{ border: '1px solid black' }}>
          <p className='pt-4'><a style={{ textDecoration: 'none', color: 'black' }} href="tel:+044-29592025">  044-29592025 </a></p>
          <div className="circleSmall shadow rounded-circle d-flex align-items-center justify-content-center mt-2" style={{ width: '60px', height: '60px', border: '1px solid black' }}>
            <div className='icon' style={{ fontSize: '1rem', color: 'white' }}><GiRotaryPhone size={20} />  </div>
          </div>
          </div>
        </div>
      </div>


      <div className='row mt-3'>
        <div className='col-lg-6 col-md-12 col-sm-12'> <h2 className='mt-3'>Location</h2>
          <div style={{ width: '100%', maxWidth: '100%' }}>
            <div style={{
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
              paddingTop: '56.25%' /* 16:9 Aspect Ratio */
            }}>
              <iframe
                className="border rounded-3 shadow"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15557.91110974784!2d79.6933825!3d12.8769704!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52c3d565dd4adf%3A0xb4b63a779d661ba9!2sPallavan%20College%20of%20Engineering!5e0!3m2!1sen!2sin!4v1716532187236!5m2!1sen!2sin"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0,
                }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>  <h2 className='mt-3'>Drop Your Queries Here</h2>
          <form className='my-3'>
            <div class="form-floating mb-3 mt-3">
              <input type="text" class="form-control" id="Name" placeholder="Enter Name" name="Name" />
              <label for="Name">Name</label>
            </div>

            <div class="form-floating mt-3 mb-3">
              <input type="text" class="form-control" id="Email" placeholder="Enter Email" name="Email" />
              <label for="Email">Email</label>
            </div>
            <div class="form-floating">
              <textarea class="form-control" id="Message" name="text" placeholder="Message goes here"></textarea>
              <label for="Message">Message</label>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
              <button className='btn mt-2 btn-primary text-center '>Submit</button>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default Contact
