import React, { useEffect } from 'react'
import Title from '../Components/Title'
import campus from '../Assets/img2.jpg'
import Road from '../Assets/Infrastructure/road.jpg'
import Parking from '../Assets/Infrastructure/bike parking.jpg'
import generator from '../Assets/Infrastructure/generator.jpg'
import hostel from '../Assets/Infrastructure/hostel.jpg'
import water from '../Assets/Infrastructure/water purifer.jpg'
import library from '../Assets/Infrastructure/library.jpg'
import CCTV from '../Assets/Infrastructure/CCTV.jpg'
import CCTV2 from '../Assets/Infrastructure/CCTV2.jpg'
import Bus from '../Assets/Infrastructure/BUS.jpg'

const Infrastructure = () => {
    useEffect(() => {
        // Preload the images when the component mounts
        const images = [campus, Road, Parking, generator, hostel, water, library, CCTV, CCTV2, Bus];
        images.forEach(imageSrc => {
            const img = new Image();
            img.src = imageSrc;
        });

        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container-sm mb-3">
            <Title title="Infrastructure" />
            <div className='mt-4 ' style={{ textAlign: 'justify' }}>
                <h5 className='text-uppercase'>Campus</h5>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                        <img alt="Campus" className="rounded-3 mb-2" style={{ objectFit: 'cover' }} src={campus} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px' />
                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                        <p>Pallavan College of Engineering is located on National Highway-4 connecting Chennai and Bangalore. It is 5 km from the temple city Kanchipuram and 70 km from state capital Chennai. The college is well connected by road and rail to reach different parts of the country.</p>
                    </div>
                </div>

                <h5 className=' mt-4 text-uppercase '>Road</h5>
                <div className='row'>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12  d-flex align-items-start flex-column  d-none d-lg-inline-block'>
                        <p>Our college boasts exceptional road facilities, ensuring smooth transportation for students, faculty, and visitors alike. With well-maintained roads and convenient access points, navigating within the campus is both efficient and hassle-free.</p>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                        <img alt="Road" className="rounded-3 mb-2" style={{ objectFit: 'cover' }} src={Road} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px' />
                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12  d-flex align-items-start flex-column d-md-block d-sm-block d-lg-none'>
                        <p>Our college boasts exceptional road facilities, ensuring smooth transportation for students, faculty, and visitors alike. With well-maintained roads and convenient access points, navigating within the campus is both efficient and hassle-free.</p>
                    </div>
                </div>

                <h5 className=' mt-4 text-uppercase'>Parking</h5>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                        <img alt="Parking" className="rounded-3 mb-2" style={{ objectFit: 'cover' }} src={Parking} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px' />
                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                        <p>
                            Our college provides outstanding parking facilities, ensuring ample space for students' vehicles and promoting convenience and accessibility. With well-organized parking areas and dedicated zones, students can safely park their vehicles without any hassle, enhancing their overall campus experience.
                        </p>
                    </div>
                </div>


                <h5 className=' mt-4 text-uppercase'>Generator</h5>
                <div className='row'>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12 d-none d-lg-inline-block'>
                        <p>
                            Our college boasts state-of-the-art generator facilities, ensuring uninterrupted power supply to cater to the needs of students and faculty. With reliable backup systems in place, students can enjoy uninterrupted learning experiences, even during power outages.
                        </p>                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                        <img alt="Generator" className="rounded-3 mb-2" style={{ objectFit: 'cover' }} src={generator} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px' />
                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12 d-md-block d-sm-block d-lg-none'>
                        <p>
                            Our college boasts state-of-the-art generator facilities, ensuring uninterrupted power supply to cater to the needs of students and faculty. With reliable backup systems in place, students can enjoy uninterrupted learning experiences, even during power outages.
                        </p>                    </div>
                </div>




                <h5 className=' mt-4 text-uppercase'>Hostel</h5>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                        <img alt="Hostel" className="rounded-3 mb-2" style={{ objectFit: 'cover' }} src={hostel} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px' />

                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                        <p>
                            Our college offers exceptional hostel facilities, providing a comfortable and conducive living environment for long-distance students. With well-equipped accommodations and attentive staff, students can feel at home while focusing on their academic pursuits.
                        </p>
                    </div>
                </div>



                <h5 className=' mt-4 text-uppercase'>Water purifer</h5>
                <div className='row'>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12 d-none d-lg-inline-block'>
                        <p>
                            Our college features top-of-the-line RO water purifier facilities, ensuring access to clean and safe drinking water for all students and staff. With advanced filtration technology, we prioritize the health and well-being of our campus community. Enjoy peace of mind knowing that quality hydration is always within reach.
                        </p>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                        <img alt="Water" className="rounded-3 mb-2" style={{ objectFit: 'cover', objectPosition: 'center' }} src={water} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px' />

                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12 d-md-block d-sm-block d-lg-none'>
                        <p>
                            Our college features top-of-the-line RO water purifier facilities, ensuring access to clean and safe drinking water for all students and staff. With advanced filtration technology, we prioritize the health and well-being of our campus community. Enjoy peace of mind knowing that quality hydration is always within reach.
                        </p>
                    </div>
                </div>


                <h5 className=' mt-4 text-uppercase'>Library</h5>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                        <img alt="library" className="rounded-3 mb-2" style={{ objectFit: 'cover' }} src={library} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px' />

                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                        <p>

                            Pallavan College of Engineering boasts a remarkable library, providing students with a rich repository of academic resources. With its extensive collection of books, journals, and digital materials, it serves as a vital hub for research, learning, and intellectual growth among the student community.
                        </p>
                    </div>
                </div>

                
                <h5 className=' mt-4 text-uppercase'>Bus facilities </h5>
                <div className='row'>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12 d-none d-lg-inline-block'>
                        <p>
                        The College has a fleet of  buses to transport students and staff from various parts of the city and its neighborhood areas to the College and back home . Transport facility will be arranged to every student. Every student is expected to avail the transport facility to enable him/her to be punctual to come to the College. Besides the regular scheduled trips, the facility is extended for occasions like after the College hour Special Coaching classes, Placements and Training, Visit to Local Industries, hospitals, NSS Camps, etc.
                        </p>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                        <img alt="Bus" className="rounded-3 mb-2" style={{ objectFit: 'cover', objectPosition: 'center' }} src={Bus} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px' />

                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 col-12 d-md-block d-sm-block d-lg-none'>
                        <p>
                        The College has a fleet of  buses to transport students and staff from various parts of the city and its neighborhood areas to the College and back home . Transport facility will be arranged to every student. Every student is expected to avail the transport facility to enable him/her to be punctual to come to the College. Besides the regular scheduled trips, the facility is extended for occasions like after the College hour Special Coaching classes, Placements and Training, Visit to Local Industries, hospitals, NSS Camps, etc.                        </p>
                    </div>
                </div>

                <h5 className=' mt-4 text-uppercase'>CCTV security</h5>
                <div className='row '>
                    <img alt="CCTV" className="rounded-3 mb-2  col col-lg-6 col-md-6 col-sm-12" style={{ objectFit: 'cover', objectPosition: 'center' }} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px'  src={CCTV} />
                    <img alt="CCTV2" className="rounded-3 mb-2  col col-lg-6 col-md-6 col-sm-12" style={{ objectFit: 'cover', objectPosition: 'center'}} width={window.innerWidth < 700 ? '100%' : '100%'} height='200px'  src={CCTV2} />
                </div>
                <div className='my-4'>
                    <p>
                        Student safety with the installation of CCTV cameras across campus, ensuring a secure learning environment. This proactive measure enhances surveillance and fosters a sense of security among students and faculty.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Infrastructure
