import React from 'react'

const Title = ({title}) => {
  return (
    <div className={`${window.innerWidth <700 ?'mt-3' : 'mt-5'}`}>
       <h1 className='ugCourse' style={{fontSize:window.innerWidth > 700 ? '40px' : '20px', lineHeight:window.innerWidth < 700 ? '20px' : '35px',letterSpacing:'1.5px'}}>{title}</h1>
    </div>
  )
}

export default Title
