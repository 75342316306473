import React from 'react'
import Title from '../Components/Title'

export const Achievements = () => {
    const achevementDetails = [
        { id: 1, year: "April 2003", position: "16" },
        { id: 2, year: "October 2003", position: "17" },
        { id: 3, year: "April 2004", position: "19" },
        { id: 4, year: "October 2004", position: "11" },
        { id: 5, year: "April 2008", position: "11" },
        { id: 6, year: "October 2008", position: "8" },
        { id: 7, year: "April 2011", position: "18" },
    ]

    return (
        <div className={`container-sm`}>
            <Title title="ACADEMIC ACHIEVMENTS" />
            <p className='mt-3' style={{ fontWeight: 'bold', fontSize: window.innerWidth < 950 ? '14px' : '16px' }}>Our college ranking position among 550 engineering colleges in Tamilnadu state</p>

            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid black' }}>S.no</th>
                            <th style={{ border: '1px solid black' }}>Year</th>
                            <th style={{ border: '1px solid black' }}>Ranking Position*</th>
                        </tr>
                    </thead>
                    <tbody>
                        {achevementDetails.map((list,index) => (
                            <tr key={list.id}>
                                <td style={{ border: '1px solid black' }}>{list.id}</td>
                                <td style={{ border: '1px solid black' }}>{list.year}</td>
                                <td style={{ border: '1px solid black' }}>{list.position}<sup>th</sup></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p className='mt-3 text-center' style={{ fontWeight: 'bold', fontSize: window.innerWidth < 950 ? '14px' : '16px' }} >                              *Ranking declared by Anna University Chennai,Tamilnadu</p>
            </div>

        </div>
    )
}
