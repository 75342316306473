import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import img2 from '../Assets/img2.jpg';
import img3 from '../Assets/img3.JPG';
import img4 from '../Assets/img4.jpg';
import img5 from '../Assets/img5.jpg';
import img6 from '../Assets/img6.jpg';
import img7 from '../Assets/img7.jpg';
import beMe from '../Assets/be-me.jpg'
import beEEE from '../Assets/BE-EEE.jpg'
import beCS from '../Assets/BE-CS.jpg'
import beECE from '../Assets/BE-ECE.jpg'
import beCE from '../Assets/BE-CE.jpg'
import BTECH from '../Assets/BTECH.jpg'
import MCA from '../Assets/MCA.jpg'
import meCS from '../Assets/ME-CS.jpg'
import MEME from '../Assets/ME-ME.jpg'
import MEAE from '../Assets/ME-AE.jpg'
import GOWRIPRIYA from '../Assets/RankHolders/GOWRI PRIYA.jpg'
import JAYABHARATHI from '../Assets/RankHolders/JAYA BHARATHI.jpg'
import INDUMATHY from '../Assets/RankHolders/INDUMATHY.jpg'
import DEEPIKA from '../Assets/RankHolders/DEEPIKA.jpg'
import SHARMILA from '../Assets/RankHolders/SHARMILA.jpg'
import GAYATHRI from '../Assets/RankHolders/GAYATHRI.jpg'
import NADHIYADEVI from '../Assets/RankHolders/NADHIYADEVI.jpg'
import VITHYA from '../Assets/RankHolders/VITHYA.jpg'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import { Link } from 'react-router-dom';
import place1 from '../Assets/placement/LOGO-1.png'
import place2 from '../Assets/placement/LOGO-2.png'
import place3 from '../Assets/placement/LOGO-3.png'
import place4 from '../Assets/placement/LOGO-4.png'
import place5 from '../Assets/placement/LOGO-5.png'
import place6 from '../Assets/placement/LOGO-6.png'
import place7 from '../Assets/placement/LOGO-7.png'
import place8 from '../Assets/placement/LOGO-8.png'
import place9 from '../Assets/placement/LOGO-9.png'
import place10 from '../Assets/placement/LOGO-10.png'

import carous1 from '../Assets/Gallery/Gallery (3).JPG'

const Home = () => {

  const photos = [
    { src: img2 },{src:carous1},{src:img3}, { src: img4 },
    {src:img5},{src:img6},
  ];

  const placements = [
    { src: place1 },
    { src: place2 },
    { src: place3 },
    { src: place4 },
    { src: place5 },
    { src: place6 },
    { src: place7 },
    { src: place8 },
    { src: place9 },
    { src: place10 },
  ]

  const ugCourses = [
    { id: 1, button: 'Read More', link: '/BE-ME', title: "B.E - Mechanical Engineering(Permanent Affiliation)", desc: 'Mechanical Engineering department was started in the year of 1997 with an intake of 60 and raised it level to 120 in the year 2012.', src: beMe },
    { id: 2, button: 'Read More', link: '/BE-EEE', title: "B.E - Electrical & Electronics Engineering(Permanent Affiliation)", desc: 'Electrical & Electronics Engineering department was started in the year of 1997 with an intake of 60. The department has produced 8 university rank holders so far.', src: beEEE },
    { id: 3, button: 'Read More', link: '/BE-CS', title: "B.E - Computer Science Engineering(Permanent Affiliation)", desc: 'The Computer Science & Engineering Department was established in the year 1999 with an intake of 60 and raised to an intake of 120 in 2014', src: beCS },
    { id: 4, button: 'Read More', link: '/BE-ECE', title: "B.E - Electronics & Communication Engineering(Permanent Affiliation)", desc: 'Electronics & Communication Engineering Department was started in the year 2001 with an intake of 60 within a short span of time grown to the level of 120 in the year of 2007.', src: beECE },
    { id: 5, button: 'Read More', link: '/BE-CE', title: "B.E - Civil Engineering", desc: 'Civil Engineering Department was started in the year of 2014 with an intake of 60. The department has state of art lab facilities in Surveys, Strength of Materials', src: beCE },
    { id: 6, button: 'Read More', link: '/B-Tech', title: "B.Tech -Information Technology", desc: 'The Information Technology Department was established in the year 2001 with an intake of 60 and raised to an intake of 90 in the year 2010..', src: BTECH },
  ];

  const pgCourses = [
    { id: 1, button: 'Read More', link: '/MCA', button: 'Read More', title: "MCA - Master of Computer Application", desc: 'Department of Master of Computer Application was started in the year 2007 with the intake of 60. From the inception, this department has produced 5 university rank holders.', src: MCA },
    { id: 2, button: 'Read More', link: '/MBA', title: "MBA -  Master of Business Administration", desc: 'An MBA degree opens doors to diverse career opportunities in finance, healthcare, supply chain, marketing, operations, business analytics, etc.', src: img7 },
    { id: 3, button: 'Read More', link: '/ME-AE', title: "M.E - Applied Electronics", desc: 'It has the right mixture of essential theoretical and practical content that is necessary to give a student the required expertise in Applied Electronics so as to meet Industry standards.', src: MEAE },
    { id: 4, button: 'Read More', link: '/ME-CS', title: "M.E - Computer Science & Engineering", desc: ' Computer Science and Engineering have a wide range of job opportunities like Software engineers, application developer, network engineer, hardware engineer, CAD engineer etc.', src: meCS },
    { id: 5, button: 'Read More', link: '/ME-ME', title: "M.E - Manufacturing Engineering", desc: 'In essence, Manufacturing Engineers ensure efficient use of resources, create high-quality products, and improve the overall manufacturing process. ', src: MEME },
  ];

  const rankHolders = [
    { name: "J.L.JAYA BHARATHI", dept: "B.E. ECE", rank: "8.94/35th Rank", profile: JAYABHARATHI },
    { name: "N.INDUMATHY", dept: "B.Tech. IT", rank: "8.54/50th Rank", profile: INDUMATHY },
    { name: "P.DEEPIKA", dept: "M.E. CSE", rank: "8.49/39th Rank", profile: DEEPIKA },
    { name: "A.GOWRI PRIYA", dept: "M.E. CSE", rank: "8.35/50th Rank", profile: GOWRIPRIYA },

    { name: "R.SHARMILA", dept: "MCA", rank: "8.97/17th Rank", profile: SHARMILA },
    { name: "S.GAYATHRI", dept: "MCA", rank: "8.75/37th Rank", profile: GAYATHRI },
    { name: "G.NADHIYADEVI", dept: "MBA", rank: "8.39/36th  Rank", profile: NADHIYADEVI },
    { name: "P.VITHYA", dept: "MBA", rank: "8.24/5Oth  Rank", profile: VITHYA }
  ]

  const [counterOn, setCounterOn] = useState(false)


  return (
    <div>
      <Carousel>
        {photos.map((photo, index) => (
          <Carousel.Item key={index} id='Carousel'>
            <div style={{ height: window.innerWidth < 550 ? '300px' : window.innerWidth < 1100 ? '500px' : '70vh' }}>
              <img
                className="d-block w-100 img-fluid"
                src={photo.src}
                alt={`Slide ${index}`}
                style={{ height: '100%', objectFit: 'cover',
                  }}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className='bgImg_sec1'>
        <div className={`container-sm  ${window.innerWidth < 1250 ? 'mt-5 pb-3' : ''}`} >

          <h2 style={{ fontSize: window.innerWidth > 700 ? '40px' : '20px', lineHeight: window.innerWidth < 700 ? '20px' : '35px', letterSpacing: 'unset' }} className={`${window.innerWidth < 600 ? 'mb-3 h4' : 'h3'} d-md-block d-sm-block d-lg-none font-semibold mb-2 ugCourse`}>
            Why To Choose Pallavan?
          </h2>

          <div className={`row mx-0 mb-5  ${window.innerWidth > 950 ? 'mt-5' : ''}`} >
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <img src={img2} style={{ height: window.innerWidth > 700 ? '330px' : '220px' }} className='w-100 shadow border rounded-4'></img>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <h2 style={{ fontSize: window.innerWidth > 850 ? '30px' : '20px', lineHeight: window.innerWidth < 700 ? '20px' : '35px', letterSpacing: 'unset' }} className={`${window.innerWidth < 600 ? 'my-3 h4' : 'h3'} d-none d-lg-inline-block font-semibold mb-2 ugCourse`}>
                Why To Choose Pallavan?
              </h2>
              <div className='my-4'>

                <ul className={`mt-3 text-dark ${window.innerWidth < 950 ? 'p-0' : ''}`} style={{ textAlign: 'justify', lineHeight: window.innerWidth < 1400 ? '' : '32px' }}>
                  <li> 30 years of academic excellence which includes 8th position in Anna University Results </li>
                  <li>95.12% of graduates received degree from the date of inception</li>
                  <li> Most of the graduates pursued Ph.D. in abroad and IIT's</li>
                  <li>  Guest lecturers by Experts from industry and leading institutions </li>
                  <li>  Faculty members are 100% committed towards the students education</li>
                  <li>  Importance given to Sports and Cultural activities other than academics</li>
                  <li>  Good placement records</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


        <div className='text-center mt-5 mb-5'>
          <h2 style={{ fontSize: window.innerWidth > 700 ? '40px' : '20px', lineHeight: window.innerWidth < 700 ? '20px' : '35px', letterSpacing: 'unset' }} className='h2 font-semibold mb-2 ugCourse' >
            UG COURSES OFFERED
          </h2>
        </div>

        <div className='container-sm '>


          <div className="d-flex justify-content-center">
            <div className="row mx-0 row-cols-1 row-cols-md-2  row-cols-lg-3 g-4">
              {ugCourses.map(item => (
                <div key={item.id} className="col ">
                  <div className={`shadow card border-0 overflow-hidden position-relative service-card`} style={{ height: window.innerWidth > 770 ? '260px' : '184px' }}>
                    <img className="card-img-top h-100 w-100 object-cover" src={item.src} alt="" />
                    <div className="card-img-overlay d-flex flex-column justify-content-center align-items-center text-center p-0 overlay">
                      <p className="card-text text-white my-2 px-2 desc" style={{ fontSize: window.innerWidth < 500 ? '12px' : '' }}>{item.desc}
                        <br /><Link to={item.link} style={{ display: !item.button ? 'none' : '' }} className="p-2 btn btn-primary text-light">{item.button}</Link>
                      </p>
                    </div>
                  </div>
                  <div style={{ minHeight: window.innerWidth > 650 ? '80px' : '' }} className={`bg-light text-center card-title shadow  p-2 text-dark border ${window.innerWidth < 500 ? 'fs-6' : 'fs-7'} fw-bold`}>{item.title}</div>
                </div>
              ))}
            </div>
          </div>
       

     
          <div className='text-center my-5'>
            <h2 style={{ fontSize: window.innerWidth > 700 ? '40px' : '20px', lineHeight: window.innerWidth < 700 ? '20px' : '35px', letterSpacing: 'unset' }} className='h2 font-semibold mb-2 ugCourse' >
              PG COURSES OFFERED
            </h2>
          </div>


          <div className="d-flex justify-content-center">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mx-0">
              {pgCourses.map(item => (
                <div key={item.id} className="col ">
                  <div className={`shadow card border-0 overflow-hidden position-relative service-card`} style={{ height: window.innerWidth > 770 ? '260px' : '184px' }}>
                    <img className="card-img-top h-100 w-100 object-cover" src={item.src} alt="" />
                    <div className="card-img-overlay d-flex flex-column justify-content-center align-items-center text-center p-0 overlay">
                      <p className="card-text text-white mt-2 px-2 desc" style={{ fontSize: window.innerWidth < 500 ? '12px' : '' }}>{item.desc}
                        <br /><Link to={item.link} style={{ display: !item.button ? 'none' : '' }} className="p-2 btn btn-primary text-light">{item.button}</Link>
                      </p>
                    </div>
                  </div>
                  <div style={{ minHeight: window.innerWidth > 650 ? '80px' : '' }} className={`bg-light text-center card-title shadow  p-2 text-dark border ${window.innerWidth < 500 ? 'fs-6' : 'fs-7'} fw-bold`}>{item.title}</div>
                </div>
              ))}
            </div>
          </div>
          </div>

      <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
        <div className='  text-center' >
          <div className="bgImg row mx-0 p-5 my-5 justify-content-center align-items-center">
            <div className='col-12 col-md-4 mb-4'>
              <div className='border rounded-3 shadow p-2 d-flex align-items-center justify-content-center flex-column'>
                <h1 style={{ fontSize: '85px' }} className='m-0 '>
                  {counterOn && <CountUp start={0} end={50} duration={3} />}+
                </h1>
                <p className='m-0 text=primary'>
                  Anna University Rank Holders <br /> from the date of inception
                </p>
              </div>
            </div>
            <div className='col-12 col-md-4 mb-4'>
              <div className='border rounded-3 shadow p-2 d-flex align-items-center justify-content-center flex-column'>
                <h1 className='m-0' style={{ fontSize: '85px' }}>
                  {counterOn && <CountUp start={0} end={95.12} duration={3} />}%
                </h1>
                <p className='m-0 text=primary'>
                  Graduates received Degree <br /> from the date of inception.
                </p>
              </div>
            </div>
            <div className='col-12 col-md-4 mb-4'>
              <div className='border rounded-3 shadow p-2 d-flex align-items-center justify-content-center flex-column'>
                <h1 className='m-0' style={{ fontSize: '85px' }}>
                  {counterOn && <CountUp start={0} end={80} duration={3} />}%
                </h1>
                <p className='m-0 text=primary'>
                  Students get First class with <br />
                  Distinction in every year
                </p>
              </div>
            </div>
          </div>
        </div>
      </ScrollTrigger>

        <div className={`container-sm`} >
          <div className='text-center my-4'>
            <h2 style={{ fontSize: window.innerWidth > 700 ? '40px' : '20px', lineHeight: window.innerWidth < 700 ? '20px' : '35px', letterSpacing: 'unset' }} className='h2 font-semibold mb-2 ugCourse' >
              RANK HOLDERS
            </h2>
          </div>
          <div className='row gap-3 d-flex justify-content-center mx-0'>
            {rankHolders.map((list, index) => (
              <div className='border bg-light shadow rounded-3 col col-lg-3 d-flex align-items-center justify-content-center' key={index}>
                <div className="d-flex my-3 flex-column align-items-center w-100 ">
                  <img
                    className='rounded-circle mb-2 shadow'
                    style={{ width: window.innerWidth < 580 ? '70px' : '130px', height: window.innerWidth < 580 ? '70px' : '130px', transform: 'scale(1.1)', border: 'black 2px solid', objectFit: 'cover', objectPosition: 'top', borderRadius: '50%' }}
                    src={list.profile}
                    alt={`Placement ${index}`}
                  />
                  <h6 className='text-center p-2  mt-1 ' style={{ fontSize: window.innerWidth < 580 ? '14px' : '16px' }}>{list.name}</h6>
                  <p className='text-center m-0 text-secondary' style={{ fontSize: window.innerWidth < 580 ? '12px' : '15px' }}>{list.dept}</p>
                  <p className='text-center  m-0  text-secondary' style={{ fontSize: window.innerWidth < 580 ? '12px' : '15px' }}>{list.rank}</p>
                </div>
              </div>
            ))}
          </div>
  
    


        <div className='text-center mt-5 '>
          <h2 style={{ fontSize: window.innerWidth > 700 ? '40px' : '20px', lineHeight: window.innerWidth < 700 ? '20px' : '35px', letterSpacing: 'unset' }} className='mt-3 h2 font-semibold  ugCourse' >
            PLACEMENTS PARTNERS
          </h2>
        </div>

        <marquee scrollamount="15" className="my-4 mx-2">
          {placements.map((list, index) => (
            <img
              key={index}
              style={{
                width: window.innerWidth < 920 ? '100px' : '200px', // Subtracting 10px for left and right gap
                marginRight: window.innerWidth < 920 ? '60px' : '100px', // Right gap between images
                height: window.innerWidth < 920 ? '60px' : '140px', // Subtracting 10px for left and right gap

              }}
              className="rounded-3"
              src={list.src}
              alt={`Placement ${index}`}
            />
          ))}
          {/* Duplicate images to loop */}
          {placements.map((list, index) => (
            <img
              key={`duplicate-${index}`} // Use a different key to avoid key duplication warning
              style={{
                width: window.innerWidth < 920 ? '100px' : '200px', // Subtracting 10px for left and right gap
                marginRight: window.innerWidth < 920 ? '60px' : '100px', // Right gap between images
                height: window.innerWidth < 920 ? '60px' : '140px', // Subtracting 10px for left and right gap

              }}
              className="rounded-3"
              src={list.src}
              alt={`Duplicate Placement ${index}`}
            />
          ))}
        </marquee>


      </div>

    </div>
  );
};

export default Home;
