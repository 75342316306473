import React, { useEffect } from 'react'
import Title from '../Components/Title';

import NSSIMG from '../Assets/NSS/NSS.JPG'

const NSS = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className={`container-sm`}>
    <Title title="National Service Scheme(NSS)" />

    <div className='row mt-5'>
        <div className='col-lg-4 col-md-12 col-sm-12'>
            <img src={NSSIMG} alt='NSSIMG' width='100%' className='rounded-3'/>
        </div>
        <div className='col-lg-8 col-md-12 col-sm-12' style={{textAlign:'justify'}}>
            <p>
            The National Service Scheme (NSS) at PALLAVAN COLLEGE OF ENGINEERING is a vibrant and integral part of our institution, dedicated to fostering social responsibility and community engagement among our students. </p>

            <p>With a mission to instill the values of service, leadership, and social awareness, NSS provides a platform for students to actively contribute to community development and nation-building.
            </p>
        </div>
    </div>
    <div className='mt-3' style={{textAlign:'justify'}}>
        <p>
        NSS empowers our students to be proactive contributors to society. Through various programs and initiatives, volunteers develop a sense of civic responsibility and empathy for the less privileged. The NSS unit at PALLAVAN COLLEGE OF ENGINEERING actively engages with local communities, organizing outreach programs, health camps, and awareness drives that address the pressing needs of the community.
        </p>
        <p>
        Apart from fostering a spirit of service, NSS focuses on the holistic development of students. Workshops, training sessions, and skill development programs are integral to our NSS initiatives. NSS at PALLAVAN COLLEGE OF ENGINEERING spearheads campaigns on various social issues such as health and hygiene, literacy, gender equality, and civic responsibilities, fostering awareness and positive change.
        </p>
        <p>
        Our NSS unit consistently receives recognition and awards for its outstanding contributions to community service and social welfare, showcasing the commitment of our students to the NSS ideals
        </p>
    </div>
</div>
  )
}

export default NSS