import React, { useEffect } from 'react'
import Title from '../Components/Title'
import Vision from '../Assets/vission.jpg'
import Mission from '../Assets/mission.jpg'

const MissionVission = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`container-sm`}>
        <Title title="Vission and Mission"/>

        <div className='row mt-3'>
          
          <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
          <h1 className=' d-md-block d-sm-block d-lg-none  '>Our Vision</h1>

            <img width="100%" className='rounded-2 shadow ' src={Vision}></img>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-start flex-column'>
          <h1 className=' d-none d-lg-inline-block  '>Our Vision</h1>

          <p style={{textAlign:'justify'}} className='pt-3'>We Pallavan College of Engineering will provide an opportunity for everybody to participate and learn, realize their full potential and raise their achievement, by providing learner centered high quality technical education and training in a professional manner.</p>

          </div>
        </div>

        <div className='row mt-5'>
          <div className='col-lg-6 col-md-12 col-sm-12 col-12  d-flex align-items-start flex-column'>
          <h1 className=''>Our Mission</h1>
          <p style={{textAlign:'justify'}} className='pt-3 d-none d-lg-inline-block '>We aspire to make the Pallavan College of Engineering, one of the Nations great Engineering College, to provide Quality Engineering Education and to prepare graduates for Engineering Practice and Advanced Education.</p>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
            <img width="100%" className='rounded-2 shadow ' src={Mission}></img>
            <p style={{textAlign:'justify'}} className='pt-3  d-md-block d-sm-block d-lg-none '>We aspire to make the Pallavan College of Engineering, one of the Nations great Engineering College, to provide Quality Engineering Education and to prepare graduates for Engineering Practice and Advanced Education.</p>

          </div>
        </div>
     </div>
  )
}

export default MissionVission
