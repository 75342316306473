import React from 'react'
import Title from '../Components/Title'
import Grevance from '../Assets/Grievance-cell.jpg'
const Grievance_redressal = () => {
    return (
        <div className={`container-sm`}>
            <Title title="Grievance Redressal Cell" />

            <div className='row mt-5'>
                <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                    <img src={Grevance} className='rounded-3' style={{ width: '100%' }}></img>
                </div>
                <div className='col-lg-8 col-md-12 col-sm-12 col-12' style={{ textAlign: 'justify' }}>
                    <p>PALLAVAN COLLEGE OF ENGINEERING has constituted a Students Grievance Redressal Committee headed by the Principal as Chairman and senior heads of the departments. The committee meets at regular intervals to resolve the complaints registered by the students.</p>
                    <p>The functions of the Committee are to look into the complaints registered by any student and judge their merit. The Grievance Redressal Committee is also empowered to look into matters of harassment. Anyone with a genuine grievance may approach the Grievance Redressal Committee members in person. In case the person is unwilling to appear in self, grievances may be sent in writing.</p>
                </div>
            </div>
            <div  className='mt-3' style={{ textAlign: 'justify' }}>
                <p>The students may feel free to put up a grievance in writing and drop it in boxes placed in the college. The committee will act upon those cases which have been forwarded along with the necessary documents.</p>
                <p>In order to address student or parent's grievance Students Grievance Redressal Committee has been constituted with the following Staff in different positions to enquire about the nature and extent of grievance.</p>
            </div>
        </div>
    )
}

export default Grievance_redressal