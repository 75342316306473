import React, { useEffect } from 'react'
import Title from '../Components/Title'
import DownloadPNG from '../Assets/downloadPNG.png'

const Basic = ({ title, img, desc, pdf }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Title title={title} />
            <div className='row my-5 '>
                <div className='col-lg-6 col-md-12'>
                    <img
                        src={img}
                        style={{ height: window.innerWidth > 800 ? '330px' : '220px', border: '1px solid black' }}
                        className='w-100 shadow  rounded-4'
                        alt={title}
                    />
                </div>
                <div className={`${window.innerWidth > 800 ? '' : 'mt-3'} col-lg-6 col-md-12 d-flex flex-column`}>
                    <div style={{ flexGrow: 1 }}>
                        <p style={{ fontSize: window.innerWidth < 800 ? '15px' : '18px', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: desc }} />
                    </div>
                </div>
            </div>

            {pdf && <>
                <div className="table-responsive">
                <table style={{ borderCollapse: 'collapse', border: '1px solid black', width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid black' }}></th>
                            <th style={{ border: '1px solid black' }} className='text-center'>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ border: '1px solid black' }}>
                                <p className='p-2' style={{fontSize : window.innerWidth <500 ? '12px': '16px'}}>You can download the {title} syllabus PDF, here</p>
                            </td>
                            <td style={{ border: '1px solid black' }}>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <a href={pdf} download={pdf}>
                                        <img  style={{height : window.innerWidth <500 ? '32px': '56px'}} src={DownloadPNG} alt="Download" height={90} />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            </>}

        </div >
    )
}

export default React.memo(Basic)
